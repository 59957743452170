var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height-100" },
    [
      _c("the-toolbar", { attrs: { title: "Plantão de Leads" } }),
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { color: "primary", indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : !_vm.isAndamento
                    ? _c("aguardando", {
                        attrs: { plantoes: _vm.plantoes },
                        on: { checkandamento: _vm.buscarPlantoes },
                      })
                    : _c("em-andamento", {
                        attrs: { plantao: _vm.plantaoEmAndamento },
                        on: { checkandamento: _vm.buscarPlantoes },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }