var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "text-h4 text-center title font-weight-regular",
      domProps: { textContent: _vm._s("PLANTÃO EM ANDAMENTO") },
    }),
    _c("div", { staticClass: "d-flex justify-center mt-4" }, [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "mt-4 mb-4" },
            [
              _c("v-progress-circular", {
                attrs: { size: 65, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.p.pausado
        ? _c(
            "div",
            [
              _c(
                "v-icon",
                {
                  staticStyle: { "font-size": "6.5rem!important" },
                  attrs: { color: "primary" },
                  on: { click: _vm.play },
                },
                [_vm._v(" mdi-play ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-icon",
                {
                  staticStyle: { "font-size": "6.5rem!important" },
                  attrs: { color: "primary" },
                  on: { click: _vm.pausar },
                },
                [_vm._v(" mdi-pause ")]
              ),
            ],
            1
          ),
    ]),
    _c("div", {
      staticClass: "text-h4 text-center title font-weight-regular mt-d",
      domProps: { textContent: _vm._s("TEMPO PARA FIM DO PLANTÃO") },
    }),
    _c("div", { staticClass: "d-flex justify-center mt-4" }, [
      _c(
        "div",
        { staticClass: "mr-3" },
        [
          _c(
            "v-icon",
            {
              staticStyle: { "font-size": "4rem!important" },
              attrs: { color: "primary" },
            },
            [_vm._v(" mdi-clock ")]
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "text-h4 font-weight-bold ml-3 align-self-center title",
        domProps: { textContent: _vm._s(_vm.showTimer) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }