<template>
  <div class="height-100">
    <the-toolbar title="Plantão de Leads">
    </the-toolbar>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <div v-if="loading" class="text-center">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <aguardando
            v-else-if="!isAndamento"
            :plantoes="plantoes"
            @checkandamento="buscarPlantoes"
          />
          <em-andamento
            v-else
            :plantao="plantaoEmAndamento"
            @checkandamento="buscarPlantoes"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import FLPlantaoService from '@/services/focusLeadPlantaoService';
import TheToolbar from '@/components/TheToolbar.vue';
import Aguardando from "./Aguardando.vue";
import EmAndamento from "./EmAndamento.vue";

export default {
  components: {
    TheToolbar,
    Aguardando,
    EmAndamento,
  },
  data() {
    return {
      plantoes: [],
      plantaoEmAndamento: {},
      isAndamento: false,
      loading: false,
      timezone: "America/Sao_Paulo",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
  },
  mounted() {
    this.buscarPlantoes();
  },
  methods: {
    async buscarPlantoes() {
      try {
        this.loading = true;
        const body = { CPFCorretor: Number(this.user.cpf) };
        const plantoes = await FLPlantaoService.buscarPlantoes(body);
        this.plantoes = plantoes;
        this.setPlantoes();
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao buscar plantões',
        });
      } finally {
        this.loading = false;
      }
    },
    setPlantoes() {
      let plantoes = JSON.parse(JSON.stringify(this.plantoes || []));
      const dataHoraAtual = moment.tz(this.timezone).toDate().getTime();
      plantoes = plantoes.filter((p) => p.DataHoraFim >= dataHoraAtual);
      this.plantaoEmAndamento = plantoes.find((p) => p.DataHoraFim >= dataHoraAtual && p.DataHoraInicio <= dataHoraAtual);
      this.isAndamento = !!this.plantaoEmAndamento;
      this.plantoes = plantoes;
    },
  },
};
</script>

<style scoped lang="scss">
  .height-100 {
    height: calc(100vh - 130px);
  }
</style>
