var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.existePlantao
      ? _c("div", [
          _c("div", {
            staticClass: "text-h4 text-center title font-weight-regular",
            domProps: {
              textContent: _vm._s("VOCÊ AINDA NÃO TEM PLANTÕES AGENDADOS"),
            },
          }),
        ])
      : _c("div", [
          _c("div", {
            staticClass: "text-h4 text-center title font-weight-regular",
            domProps: { textContent: _vm._s("TEMPO PARA O PRÓXIMO PLANTÃO") },
          }),
          _c("div", { staticClass: "d-flex justify-center mt-d" }, [
            _c(
              "div",
              { staticClass: "mr-3" },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "font-size": "5rem!important" },
                    attrs: { color: "primary" },
                  },
                  [_vm._v(" mdi-account-clock ")]
                ),
              ],
              1
            ),
            _c("div", {
              staticClass:
                "text-h4 font-weight-bold ml-3 align-self-center title",
              domProps: { textContent: _vm._s(_vm.showTimer) },
            }),
          ]),
          _c("div", {
            staticClass: "text-h4 mt-d text-center title",
            domProps: {
              textContent: _vm._s(
                _vm.plantao.length > 1 ? "PRÓXIMOS PLANTÕES" : "PRÓXIMO PLANTÃO"
              ),
            },
          }),
          _c("div", { staticClass: "mt-d" }, [
            _c("div", { staticClass: "d-flex justify-center" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "max-width": "600px!important",
                    "max-height": "230px!important",
                    overflow: "auto!important",
                  },
                },
                _vm._l(_vm.plantao, function (p, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-large": "", color: "primary" } },
                            [_vm._v(" mdi-calendar-month ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            staticClass: "title font-weight-light",
                            domProps: {
                              textContent: _vm._s(
                                _vm.formataData(p.DataHoraInicio, p.DataHoraFim)
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }