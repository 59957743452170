<template>
  <div>
    <div v-if="!existePlantao">
      <div
        class="text-h4 text-center title font-weight-regular"
        v-text="'VOCÊ AINDA NÃO TEM PLANTÕES AGENDADOS'"
      ></div>
    </div>
    <div v-else>
      <div
        class="text-h4 text-center title font-weight-regular"
        v-text="'TEMPO PARA O PRÓXIMO PLANTÃO'"
      ></div>
      <div class="d-flex justify-center mt-d">
        <div class="mr-3">
          <v-icon style="font-size: 5rem!important" color="primary">
            mdi-account-clock
          </v-icon>
        </div>
        <div
          class="text-h4 font-weight-bold ml-3 align-self-center title"
          v-text="showTimer"
        ></div>
      </div>
      <div
        class="text-h4 mt-d text-center title"
        v-text="plantao.length > 1 ? 'PRÓXIMOS PLANTÕES' : 'PRÓXIMO PLANTÃO'"
      ></div>
      <div class="mt-d">
        <div class="d-flex justify-center">
          <div style="max-width: 600px!important; max-height: 230px!important; overflow: auto!important;">
            <v-list-item v-for="(p, i) in plantao" :key="i">
              <v-list-item-avatar>
                <v-icon x-large color="primary">
                  mdi-calendar-month
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="title font-weight-light"
                  v-text="formataData(p.DataHoraInicio, p.DataHoraFim)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    plantoes: {
      default: [],
    },
  },
  data() {
    return {
      iTimer: null,
      plantao: [],
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalSeconds: 1,
      },
      isTimerStarted: false,
      timezone: "America/Sao_Paulo",
    };
  },
  computed: {
    showTimer() {
      let {
        hours,
        minutes,
        seconds,
      } = JSON.parse(JSON.stringify(this.timer));
      const { days } = JSON.parse(JSON.stringify(this.timer));
      if (Number(days) > 0) {
        const strDays = Number(days) > 1 ? 'dias' : 'dia';
        return `${days} ${strDays}`;
      }
      hours = Number(hours) < 10 ? `0${hours}` : hours;
      minutes = Number(minutes) < 10 ? `0${minutes}` : minutes;
      seconds = Number(seconds) < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
    existePlantao() {
      return !!this.plantao.length;
    },
    primeiroPlantao() {
      return this.plantao[0];
    },
  },
  watch: {
    plantoes() {
      this.setPlantoes();
      if (this.existePlantao && !this.isTimerStarted) {
        this.startTimer();
      }
    },
    "timer.totalSeconds": {
      handler(value) {
        if (value <= 0) {
          this.isTimerStarted = false;
          clearInterval(this.iTimer);
          this.$emit('checkandamento');
        }
      },
    },
  },
  mounted() {
    this.setPlantoes();
    if (this.existePlantao && !this.isTimerStarted) {
      this.startTimer();
    }
  },
  methods: {
    setPlantoes() {
      const plantoes = JSON.parse(JSON.stringify(this.plantoes || []));
      plantoes.sort((a, b) => {
        if (a.DataHoraInicio < b.DataHoraInicio) return -1;
        if (a.DataHoraInicio > b.DataHoraInicio) return 1;
        return 0;
      });
      this.plantao = plantoes;
    },
    startTimer() {
      this.isTimerStarted = true;
      this.iTimer = setInterval(() => {
        const dataHoraAtual = moment.tz(this.timezone);
        const dataHoraInicio = moment.tz(this.primeiroPlantao.DataHoraInicio, this.timezone);

        const m = dataHoraInicio.diff(dataHoraAtual, 'minutes');
        const s = dataHoraInicio.diff(dataHoraAtual, 'seconds');

        this.timer.days = Math.floor(m / 1440);
        this.timer.hours = Math.floor((m % 1440) / 60);
        this.timer.minutes = Math.floor((m % 1440) % 60);
        this.timer.seconds = Math.floor((s % 1440) % 60);
        this.timer.totalSeconds = s;
      }, 1000);
    },
    formataData(dataInicial, dataFinal) {
      const dataIniTratatada = moment.tz(dataInicial, this.timezone).format('DD/MM/YYYY HH:mm');
      const dataFimTratatada = moment.tz(dataFinal, this.timezone).format('DD/MM/YYYY HH:mm');
      return `${dataIniTratatada} ATÉ ${dataFimTratatada}`;
    },
  },
};
</script>

<style scoped>

  .mt-d {
    margin-top: 3rem!important;
  }
</style>
