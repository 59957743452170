<template>
  <div>
    <div
      class="text-h4 text-center title font-weight-regular"
      v-text="'PLANTÃO EM ANDAMENTO'"
    ></div>
    <div class="d-flex justify-center mt-4">
      <div v-if="loading" class="mt-4 mb-4">
        <v-progress-circular
          :size="65"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else-if="p.pausado">
        <v-icon style="font-size: 6.5rem!important" color="primary" @click="play">
          mdi-play
        </v-icon>
      </div>
      <div v-else>
        <v-icon style="font-size: 6.5rem!important" color="primary" @click="pausar">
          mdi-pause
        </v-icon>
      </div>
    </div>
    <div
      class="text-h4 text-center title font-weight-regular mt-d"
      v-text="'TEMPO PARA FIM DO PLANTÃO'"
    ></div>
    <div class="d-flex justify-center mt-4">
      <div class="mr-3">
        <v-icon style="font-size: 4rem!important" color="primary">
          mdi-clock
        </v-icon>
      </div>
      <div
        class="text-h4 font-weight-bold ml-3 align-self-center title"
        v-text="showTimer"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import FLPlantaoService from '@/services/focusLeadPlantaoService';

export default {
  props: {
    plantao: {
      default: {},
    },
  },
  data() {
    return {
      p: {
        idPlantao: 0,
        DataHoraFim: 0,
        pausado: false,
      },
      iTimer: null,
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalSeconds: 1,
      },
      isTimerStarted: false,
      loading: false,
      timezone: "America/Sao_Paulo",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    showTimer() {
      let {
        hours,
        minutes,
        seconds,
      } = JSON.parse(JSON.stringify(this.timer));
      const { days } = JSON.parse(JSON.stringify(this.timer));
      if (Number(days) > 0) {
        const strDays = Number(days) > 1 ? 'dias' : 'dia';
        return `${days} ${strDays}`;
      }
      hours = Number(hours) < 10 ? `0${hours}` : hours;
      minutes = Number(minutes) < 10 ? `0${minutes}` : minutes;
      seconds = Number(seconds) < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  watch: {
    plantao() {
      this.setPlantao();
      if (!this.isTimerStarted) {
        this.startTimer();
      }
    },
    "timer.totalSeconds": {
      handler(value) {
        if (value <= 0) {
          this.isTimerStarted = false;
          clearInterval(this.iTimer);
          this.$emit('checkandamento');
        }
      },
    },
  },
  mounted() {
    this.setPlantao();
    if (!this.isTimerStarted) {
      this.startTimer();
    }
  },
  methods: {
    setPlantao() {
      const plantao = JSON.parse(JSON.stringify(this.plantao || {}));
      this.p.idPlantao = plantao.idPlantao;
      this.p.DataHoraFim = plantao.DataHoraFim || 0;
      this.p.pausado = plantao.pausado;
    },
    startTimer() {
      this.isTimerStarted = true;
      this.iTimer = setInterval(() => {
        const dataHoraAtual = moment.tz(this.p.DataHoraFim, this.timezone);
        const dataHoraFinal = moment.tz(this.timezone);

        const m = dataHoraAtual.diff(dataHoraFinal, 'minutes');
        const s = dataHoraAtual.diff(dataHoraFinal, 'seconds');

        this.timer.days = Math.floor(m / 1440);
        this.timer.hours = Math.floor((m % 1440) / 60);
        this.timer.minutes = Math.floor((m % 1440) % 60);
        this.timer.seconds = Math.floor((s % 1440) % 60);
        this.timer.totalSeconds = s;
      }, 1000);
    },
    async pausar() {
      try {
        this.loading = true;
        const form = JSON.parse(JSON.stringify(this.p));
        const body = {
          idPlantao: Number(form.idPlantao),
          CPFCorretor: Number(this.user.cpf),
          acao: true,
          loginExecutor: (this.user.contato && this.user.contato.email) || this.user.nome,
        };
        await FLPlantaoService.mudarStatus(body);
        this.p.pausado = true;
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao pausar plantão',
        });
      } finally {
        this.loading = false;
      }
    },
    async play() {
      try {
        this.loading = true;
        const form = JSON.parse(JSON.stringify(this.p));
        const body = {
          idPlantao: Number(form.idPlantao),
          CPFCorretor: Number(this.user.cpf),
          acao: false,
          loginExecutor: (this.user.contato && this.user.contato.email) || this.user.nome,
        };
        await FLPlantaoService.mudarStatus(body);
        this.p.pausado = false;
        this.$router.push({ name: "areaLogada.lead", params: { plantao: true } });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao iniciar plantão',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
  .mt-d {
    margin-top: 3rem!important;
  }
</style>
